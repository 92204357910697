import React from 'react'
import usePageQuery from '../../pageQuery'
import { Section, BlogCardsDiv, BlogCardTextContent, CategoryText, SubCategoryText, CategoriesDiv, BlogCard } from './style'
import Icon from 'src/components/UI/MarkdownIcon'
import dataJson from './assets/data/data.json'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type BlogCardType = {
  image: string;
  category: string;
  subCategory?: string;
  title: string;
  description: string;
  date: string;
  link: string;
}

const AcompanheNossoBlog = () => {
  const data = usePageQuery()
  const blogCard: BlogCardType[] = dataJson
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='hero-rlp' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-md-center'>Os melhores conteúdos sobre investimentos</h2>
          </div>
          <div className='col-12'>
            <BlogCardsDiv className='d-md-flex justify-content-md-center'>
              {blogCard.map((card: BlogCardType) => (
                <BlogCard
                  href={card.link}
                  key={card.image}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_11',
                      element_action: 'click button',
                      element_name: 'Leia mais',
                      section_name: 'Acompanhe nosso blog e fique por dentro do assunto',
                    })
                  }}
                >
                  <div>
                    <Img fluid={data[card.image].fluid} alt='Ilustração do post no blog Inter.' />
                  </div>
                  <BlogCardTextContent key={card.image}>
                    <div>
                      <CategoriesDiv className='d-flex d-md-block d-lg-flex'>
                        <div>
                          <CategoryText className='fs-12 lh-15 fw-700 text-white mr-3 d-md-inline' dangerouslySetInnerHTML={{ __html: card.category }} />
                        </div>
                        <div>
                          {card.subCategory && <SubCategoryText className='fs-12 lh-15 fw-700 text-grayscale--500 d-md-inline' dangerouslySetInnerHTML={{ __html: card.subCategory }} />}
                        </div>
                      </CategoriesDiv>
                      <div className=''>
                        <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
                        <h6 className='fs-12 lh-15 fw-700 text-grayscale--300 mb-0' dangerouslySetInnerHTML={{ __html: card.date }} />
                      </div>
                      <div className='d-none d-md-block'>
                        <p className='fs-16 lh-20 text-grayscale--500 mb-0 mt-lg-3' dangerouslySetInnerHTML={{ __html: card.description }} />
                        <div className='mt-2'>
                          <span
                            title='Leia mais'
                            className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-md-block'
                          >
                            Leia mais
                            <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </BlogCardTextContent>
                </BlogCard>
                ))}
            </BlogCardsDiv>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AcompanheNossoBlog
