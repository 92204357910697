import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

type OQueERLPProps = {
  openModal: () => void;
};

const OQueERLP = ({ openModal }: OQueERLPProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section id='hero-rlp' className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='d-none d-md-block col-12 col-md-6 col-lg-5'>
            <Img fluid={data.bannerOQueERLP.fluid} alt='Pessoa de óculos com camisa marrom e branca no escritório pesquisando preços de ações.' />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'>O que é RLP?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 mb-4'>O RLP é um serviço que permite à corretora atuar como contraparte das negociações, facilitando a compra ou venda de grandes lotes de ativos de um mercado agressivo. Dessa forma, o principal objetivo do serviço é prover maior liquidez ao investidor, além de melhores preços.</p>
            <button
              onClick={openModal}
              className='d-none d-md-block btn btn-orange--extra mx-auto text-white text-none'
            >
              Ative agora
            </button>
            <div>
              <a
                href='https://intergo.app/5b3fb84b'
                className='d-flex d-md-none btn btn-orange--extra mx-auto text-white text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    element_action: 'click button',
                    element_name: 'Ative agora',
                    section_name: 'O que é RLP?',
                  })
                }}
              >
                Ative agora
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OQueERLP
