import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

`

export const BorderDiv = styled.div`
  background-color: ${white};
  padding: 16px;
  @media ${device.tablet} {
    background-color: transparent;
    border: 2px solid ${grayscale[200]};
    border-radius: 16px;
    padding: 22px;
  }
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 38px 1fr 1fr 1fr 1fr;

  @media ${device.tablet} {
    grid-template-columns: 120px 1fr 1fr 1fr 1fr;
  }
  @media ${device.desktopLG} {
    grid-template-columns: 168px 1fr 1fr 1fr 1fr;
  }
  @media ${device.desktopXL} {
    grid-template-columns: 288px 1fr 1fr 1fr 1fr;
  }

  .border-right {
    border-right: 1px solid ${grayscale[200]};
  }

  .border-bottom {
    border-bottom: 1px solid ${grayscale[200]};
  }
`

export const ListContainer = styled.div`
  div:last-child p {
      border-bottom: none !important;
  }
`

export const Underbar = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${white};
`

export const VerticalLine = styled.div`
  @media ${device.tablet} {
    margin: 0 24px 0 24px;
    width: 1px; 
    border-left: 1px dashed ${grayscale[200]};
  }
`

export const ContractsBlock = styled.div`
  width: 140px;
  @media ${device.tablet} {
    width: 220px;
  }
  @media ${device.desktopLG} {
    width: 260px;
  }
`
