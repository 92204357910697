import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

`

export const Card = styled.div`
  svg {
    width: 24px;
    height: 24px;
    @media ${device.tablet} {
      width: 32px;
      height: 32px;
    }
  }

  .svg-div {
    margin-right: 32px;
    @media ${device.tablet} {
      margin-right: 24px;
    }
  }

  @media ${device.tablet} {
    border: 1px solid ${grayscale[200]};
    border-radius: 16px;
    padding: 22px;
  }
`
