import React, { useState } from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import jsonData from './assets/data/data.json'

import { Section } from './style'

type CarouselInfoType = {
  title: string;
  description: string;
}

const ComoEFacilAtivarORLP = () => {
  const [ imageIndex, setImageIndex ] = useState(0)

  const imageObjects = [
    {
      src: 'carouselBannerStepAcesseSuperApp',
      alt: 'Super App do Inter com a tela para fazer o login.',
    },
    {
      src: 'carouselBannerMenuInvestimentos',
      alt: 'Super App do Inter mostrando o menu principal e outras funcionalidades da conta digital.',
    },
    {
      src: 'carouselBannerCliqueEmConfiguracoes',
      alt: 'Super App do Inter Invest aberto na área de rendimentos.',
    },
    {
      src: 'carouselBannerVaAteAdesaoAoRLP',
      alt: 'Super App do Inter Invest mostrando os termos de investimento.',
    },
    {
      src: 'carouselBannerCliqueEmAtivar',
      alt: 'Super App do Inter Invest mostrando como ativar o RLP.',
    },
  ]

  const data = usePageQuery()

  const changeImage = (nextSlide: number) => {
    setImageIndex(nextSlide)
  }
  return (
    <Section id='hero-rlp' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4'>
            <Img fluid={data[imageObjects[imageIndex].src].fluid} alt={imageObjects[imageIndex].alt} />
          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-16 lh-20 fs-lg-20 lh-lg-26 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>Veja como é fácil ativar o RLP na Inter Invest</h2>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(nextSlide: number) => changeImage(nextSlide)}
            >
              {
                jsonData.map((item: CarouselInfoType, index: number) => (
                  <div
                    key={index}
                    className=''
                  >
                    <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3' dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lg-xl-22 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoEFacilAtivarORLP
