import React from 'react'

import ValuesRlp from 'src/assets/data/investimentos/rlp.json'
import useWidth from 'src/hooks/window/useWidth'

const WIDTH_MD = 768

export const RlpNumbersInPai = () => {
  const windowWidth = useWidth(300)

  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Volume total negociado
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.totalValue}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.totalValue}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Clientes atendidos
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.attendedClients}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.attendedClients}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Clientes beneficiados
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.benefitedCustomers}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.benefitedCustomers}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Contratos executados
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.executedContracts}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.executedContracts}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Ofertas executadas
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.executedOffers}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.executedOffers}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Ofertas melhoradas
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.enhancedOffers}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.enhancedOffers}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-4 col-lg-4 mt-4'>
          <div
            className={`border-grayscale--100 border-bottom bg-white border-md ${
              windowWidth >= WIDTH_MD ? 'rounded-4' : ''
            } px-md-2 px-lg-3 py-md-4`}
          >
            <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'>
              Contratos melhorados
            </h3>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WIN:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.win.enhancedContracts}
              </span>
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 fw-400 mb-md-0'>
              WDO:{' '}
              <span className='text-grayscale--500'>
                {ValuesRlp.wdo.enhancedContracts}
              </span>
            </p>
          </div>
        </div>
        <div className='col-12 col-md-8 d-flex align-items-center mt-3'>
          <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 mb-0'>
            <a
              href={ValuesRlp.win.url || ValuesRlp.wdo.url}
              target='__blank'
              className='text-orange--extra fw-700'
            >
              Consulte aqui
            </a>{' '}
            os dados dos útimos seis meses.
          </p>
        </div>
      </div>
    </div>
  )
}
