import React from 'react'

import FAQ from 'src/components/Faq'
import ViewMore from 'src/components/ViewMore'

import pageContext from '../../pageContext.json'

import { FAQSection } from './style'

type FaqDataProps = {
  faqData: typeof pageContext.structuredData;
}

const Faq = ({ faqData }: FaqDataProps) => {
  return (
    <FAQSection id='faq' className='pt-5 py-md-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-md-center mb-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 mb-5'>Perguntas frequentes</h2>
          </div>
          <ViewMore classwrapper='d-md-flex w-100 flex-wrap' color='gray' height='790'>
            <FAQ id='rlp' className='summary-content px-0' answerData={faqData.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} />
          </ViewMore>
        </div>
      </div>
    </FAQSection>
  )
}

export default Faq
