import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`


.btn {
  height: 48px !important;
  @media ${device.desktopXL} {
    max-width: 456px !important;
  }
}
`
