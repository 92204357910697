import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataJson from './assets/data/data.json'
import { IValoresDoMercadoFuturo } from './types'

import * as S from './style'

const ValoresDoMercadoFuturo = () => {
  const data: IValoresDoMercadoFuturo[] = dataJson
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/'

  return (
    <S.Section className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 font-sora mb-lg-5 text-md-center'>Valores do mercado futuro no Inter</h2>
            <div>
              <S.BorderDiv>
                <h3 className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fw-700 text-grayscale--500 text-md-center'>Operações via Home Broker ou plataformas</h3>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 fw-400 text-grayscale--500 mb-0 text-md-center font-sora'>Day Trade em Índice e Dólar</p>
              </S.BorderDiv>
              <div className='d-flex justify-content-between justify-content-md-center p-3'>
                <S.ContractsBlock>
                  <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 text-grayscale--500 text-md-right font-sora'>Com <span className='fw-700'>RLP</span></p>
                  <p className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 text-grayscale--500 fw-700 mb-0 text-md-right font-sora'> <span className='d-md-none'>R$ 0,00</span><span className='d-none d-md-inline'>Custo zero</span></p>
                </S.ContractsBlock>
                <S.VerticalLine />
                <S.ContractsBlock>
                  <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 text-grayscale--500 text-md-left font-sora'>Sem <span className='fw-700'>RLP</span></p>
                  <p className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 text-grayscale--500 fw-700 font-sora text-md-left mb-0 d-inline'>R$ 0,70</p><span className='fs-14 lh-16 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'> por contrato</span>
                </S.ContractsBlock>
              </div>
              <S.Underbar className='d-md-none' />
            </div>
          </div>
          <div className='col-12'>
            <S.ListContainer>
              {data.map((item: IValoresDoMercadoFuturo) => (
                <div key={item.id} className='my-4'>
                  <S.BorderDiv>
                    <h3 className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 fw-700 text-grayscale--500 text-md-center font-sora' dangerouslySetInnerHTML={{ __html: item.title }} />
                    <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 fw-400 text-grayscale--500 mb-0 text-md-center font-sora' dangerouslySetInnerHTML={{ __html: item.subTitle }} />
                  </S.BorderDiv>
                  <div className='d-flex justify-content-between justify-content-md-center p-3'>
                    <S.ContractsBlock>
                      <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 text-grayscale--500 text-md-right font-sora'>Minicontrato</p>
                      <div className='text-md-right'>
                        <p className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 text-grayscale--500 fw-700 font-sora text-md-right mb-0 d-inline'>{item.minicontratos}</p><span className='fs-14 lh-16 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'> por contrato</span>
                      </div>
                    </S.ContractsBlock>
                    <S.VerticalLine />
                    <S.ContractsBlock>
                      <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-20 lh-lg-26 text-grayscale--500 text-md-left font-sora'>Contratos cheios</p>
                      <div className='text-md-left'>
                        <p className='fs-14 lh-16 fs-md-20 lh-md-25 fs-lg-24 lh-lg-30 text-grayscale--500 fw-700 font-sora text-md-left mb-0 d-inline'>{item.contratosCheios}</p><span className='fs-14 lh-16 fs-md-16 lh-md-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600'> por contrato</span>
                      </div>
                    </S.ContractsBlock>
                  </div>
                  <S.Underbar className='d-md-none' />
                </div>
              ))}
            </S.ListContainer>
          </div>
          <div className='col-12 mt-4'>
            <p className='fs-12 h-15 fs-md-14 lh-md-16 text-grayscale--500 text-md-center'>A zeragem será feita caso o cliente atinja uma perda de 70% do capital alocado como margem ou caso tenha posições em aberto às 18h15</p>
            <p className='fs-14 lh-16 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--500 text-md-center mb-0'>Consulte a{' '}
              <a
                href='https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/'
                rel='nofollow'
                className='fw-700'
                target='__blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_10',
                      element_action: 'click button',
                      element_name: 'Valores do mercado futuro no Inter',
                      section_name: 'Tabela Operacional Básica',
                      redirect_url: link,
                    })
                }}
              >
                Tabela Operacional Básica
              </a> para
              <span className=''>
                custos dos demais ativos
              </span> <span className=''>operados via mesa</span>
            </p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ValoresDoMercadoFuturo
