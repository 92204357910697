import React from 'react'

import { RlpNumbersInPai } from 'src/components/RlpNumbersInPai'

import { NumbersRLPInterInvest } from './style'

const Numeros = () => {
  return (
    <NumbersRLPInterInvest id='numeros-do-rlp' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='col-12 text-left px-0'>
          <h2 className='fw-600 fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-0'>Números do RLP na Inter Invest</h2>
        </div>
        <div className='row mt-4'>
          <div className='col-12'>
            <RlpNumbersInPai />
          </div>
        </div>
      </div>
    </NumbersRLPInterInvest>
  )
}

export default Numeros
