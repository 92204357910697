import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: #FFF2E7;
  @media ${device.tablet} {
    background-color: ${white};
  }

  h3 {
    font-family: 'Inter' !important;
  }

  .btn {
    height: 48px !important;
    @media ${device.desktopXL} {
      min-width: 456px;
    }
  }
`

export const Card = styled.div`
  .svg-div {
    margin-right: 16px;
  }
`
