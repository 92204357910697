import React from 'react'

import ArrowDown from 'inter-frontend-svgs/lib/orangeds/MD/arrow-down'

import { orange } from 'src/styles/colors'

import { Section, TitleBox, BoxLeft, BoxRight, BoxWithRLP, BoxOutRLP } from './style'

type OQueERLPProps = {
  openModal: () => void;
};

const ComoOProvedorDeLiquidezFunciona = ({ openModal }: OQueERLPProps) => {
  return (
    <Section id='hero-rlp' className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center mb-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-grayscale--500'>Como o Provedor de Liquidez para Varejo funciona?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-lg-22 fw-400 text-grayscale--500'>O Retail Liquidity Provider (RLP), ou provedor de liquidez ao varejo, permite que a corretora, ou banco, atue como a contraparte em algumas operações na bolsa de valores. Isso significa que ao invés de ter que esperar um outro investidor disposto a comprar ou vender determinado ativo, a operação pode ser feita diretamente com a corretora.
              <span className='d-block mt-3'>A lógica de funcionamento da bolsa de valores é basicamente oferta e demanda.</span>
              <span className='d-block mt-3'>Veja o exemplo abaixo:</span>
            </p>
          </div>
          <div className='col-12'>
            <TitleBox>
              <h3 className='fs-16 lh-20 fs-md-20 lh-md-26 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 text-center mb-0'>Você quer vender 10 contratos a mercado</h3>
            </TitleBox>
            <div className='py-4 py-md-3 text-center'>
              <ArrowDown color={orange.extra} width={32} height={32} />
            </div>
            <div className='main-div row'>
              <div className='esq col-6 px-0 px-md-3'>
                <div className='row'>
                  <div className='col-4 pr-0 pr-md-3 mb-2'>
                    <div>
                      <span className='d-block fs-14 lh-17 fw-700 text-grayscale--500 text-left mb-2 d-md-none'>Qtde.</span>
                      <span className='fs-14 lh-17 fs-lg-16 lh-lg-21 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-left mb-2 mb-xl-3 d-none d-md-block'>Quantidade</span>
                    </div>
                    <BoxLeft className='quant'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500'>3</span>
                    </BoxLeft>
                  </div>
                  <div className='col-8 pl-0 pl-md-3 mb-2'>
                    <div>
                      <span className='d-block fs-14 lh-17 fs-lg-16 lh-lg-21 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-left mb-2 mb-xl-3'>Compra</span>
                    </div>
                    <BoxLeft className='compra'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500'>105,000</span>
                    </BoxLeft>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4 pr-0 pr-md-3'>
                    <BoxLeft className='quant'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500'>7</span>
                    </BoxLeft>
                  </div>
                  <div className='col-8 pl-0 pl-md-3'>
                    <BoxLeft className='compra'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500'>104,995</span>
                    </BoxLeft>
                  </div>
                </div>
              </div>
              <div className='dir col-6 px-0 px-md-3'>
                <div className='row'>
                  <div className='col-8 pr-0 pr-md-3 mb-2'>
                    <div>
                      <span className='d-block fs-14 lh-17 fs-lg-16 lh-lg-21 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-right mb-2 mb-xl-3'>Venda</span>
                    </div>
                    <BoxRight className='venda'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-right'>105,005</span>
                    </BoxRight>
                  </div>
                  <div className='col-4 pl-0 pl-md-3 mb-2'>
                    <div>
                      <span className='d-block fs-14 lh-17 fw-700 text-grayscale--500 text-left mb-2 d-md-none text-right'>Qtde.</span>
                      <span className='fs-14 lh-17 fs-lg-16 lh-lg-21 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-left mb-2 mb-xl-3 d-none d-md-block text-md-right'>Quantidade</span>
                    </div>
                    <BoxRight className='quant'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-md-right'>6</span>
                    </BoxRight>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-8 pr-0 pr-md-3'>
                    <BoxRight className='venda'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-right'>105,015</span>
                    </BoxRight>
                  </div>
                  <div className='col-4 pl-0 pl-md-3'>
                    <BoxRight className='quant'>
                      <span className='d-block fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-26 fw-700 text-grayscale--500 text-md-right'>13</span>
                    </BoxRight>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-4 py-md-3 text-center'>
              <ArrowDown color={orange.extra} width={32} height={32} />
            </div>
            <div className='row'>
              <div className='col-6 px-0 px-md-3'>
                <BoxWithRLP>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 mb-2 text-md-center'>Com RLP você vende</p>
                  <span className='d-block fs-20 lh-26 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 text-center'>10</span>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 text-center'>105,000</p>
                </BoxWithRLP>
              </div>
              <div className='col-6 px-0 px-md-3'>
                <BoxOutRLP>
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 mb-2 text-md-center'>Sem RLP você vende</p>
                  <div className='d-flex justify-content-between justify-content-md-center align-items-center'>
                    <div className='mr-md-4'>
                      <span className='d-block fs-20 lh-26 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 text-center'>3</span>
                      <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 text-center'>105,000</p>
                    </div>
                    <div>
                      <span className='fs-17 fw-600 text-grayscale--500 text-center'>+</span>
                    </div>
                    <div className='ml-md-4'>
                      <span className='d-block fs-20 lh-26 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 fw-600 text-grayscale--500 text-center'>7</span>
                      <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fw-400 text-grayscale--400 text-center'>104,995</p>
                    </div>
                  </div>
                </BoxOutRLP>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoOProvedorDeLiquidezFunciona
