import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { CardContainer } from './style'

const QuandoNegociar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const link = 'https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/horario-de-negociacao/acoes/'
  return (
    <section className='bg-white py-5'>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-12 text-left text-md-center px-0'>
            <h2 className='fw-600 text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50'>Confira quando negociar</h2>
          </div>
        </div>
        <div className='row justify-content-lg-center'>
          <div className='col-12 col-lg-10 col-xl-8 mb-4'>
            <CardContainer>
              <div className='header'>
                <p className='text-grayscale--500 fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 mb-2'>Índice</p>
                <p className='text-grayscale--500 fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 mt-md-2 mb-0'>IND e WIN</p>
              </div>
              <div className='body'>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Pré-abertura</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>08h55</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Início</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>09h00</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Fechamento</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>18h25</p>
                </div>
              </div>
            </CardContainer>
          </div>
          <div className='col-12 col-lg-10 col-xl-8 mb-4'>
            <CardContainer>
              <div className='header'>
                <p className='text-grayscale--500 fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 mb-2'>Dólar</p>
                <p className='text-grayscale--500 fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 mt-md-2 mb-0'>DOL e WDO</p>
              </div>
              <div className='body'>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Pré-abertura</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>08h55</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Início</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>09h00</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Fechamento</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>18h30</p>
                </div>
              </div>
            </CardContainer>
          </div>
          <div className='col-12 col-lg-10 col-xl-8'>
            <CardContainer>
              <div className='header'>
                <p className='text-grayscale--500 fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 mb-2'>Ações</p>
              </div>
              <div className='body'>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Pré-abertura</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>09h45</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Início</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>10h00</p>
                </div>
                <div className='info'>
                  <p className='fs-12 lh-14 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-2 mb-md-3'>Fechamento</p>
                  <p className='fs-14 lh-17 fs-md-20 lh-md-25 fs-xl-24 lh-xl-30 fw-600 text-grayscale--500 mb-0'>18h00</p>
                </div>
              </div>
            </CardContainer>
          </div>
        </div>
        <div className='col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2'>
          <p
            className='mt-4 fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-md-center'
          >
            Para outros ativos,{' '}
            <a
              href='https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/horario-de-negociacao/acoes/'
              target='__blank'
              rel='nofollow'
              className='text-orange--500 fw-600'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'consulte o site da B3',
                  section_name: 'Confira quando negociar',
                  redirect_url: link,
                })
              }}
            >
              consulte o site da B3
            </a>
          </p>
          <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-md-center'>Sobre a zeragem na BM&F: caso o investidor não zere sua posição até 18h15 ou atinja seu limite máximo de garantias disponíveis, a área de riscos da Inter DTVM realizará a zeragem compulsória.</p>
          <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-grayscale--400 text-md-center'>Os horários de operações de dólar estão sujeitos à antecipação de 1h, devido ao horário de verão nos EUA.</p>
        </div>
      </div>
    </section>
  )
}

export default QuandoNegociar
