import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerOQueERLP: imageSharp(fluid: {originalName: { regex: "/banner-o-que-e-rlp/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerORLPNaInterInvest: imageSharp(fluid: {originalName: { regex: "/banner-o-rlp-na-inter-invest/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carouselBannerStepAcesseSuperApp: imageSharp(fluid: {originalName: { regex: "/banner-carousel-como-ativar-rlp-step-acesse-o-super-app/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carouselBannerMenuInvestimentos: imageSharp(fluid: {originalName: { regex: "/banner-carousel-como-ativar-rlp-step-menu-investimentos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carouselBannerCliqueEmConfiguracoes: imageSharp(fluid: {originalName: { regex: "/banner-carousel-como-ativar-rlp-step-clique-em-configuracoes/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carouselBannerVaAteAdesaoAoRLP: imageSharp(fluid: {originalName: { regex: "/banner-carousel-como-ativar-rlp-step-va-ate-adesao-ao-rlp/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      carouselBannerCliqueEmAtivar: imageSharp(fluid: {originalName: { regex: "/banner-carousel-como-ativar-rlp-step-clique-em-ativar/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerAindaNaoInvestePeloInter: imageSharp(fluid: {originalName: { regex: "/banner-ainda-nao-investe-pelo-inter/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      blogCardsOQueERlp: imageSharp(fluid: { originalName: { regex: "/banner-blog-o-que-e-rlp/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogCardsBMFOQueEEComoInvestir: imageSharp(fluid: { originalName: { regex: "/banner-blog-bmf-o-que-e-e-como-investir/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      blogCardsPortalInterInvest: imageSharp(fluid: { originalName: { regex: "/banner-blog-portal-inter-invest/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerRLPNaB3: imageSharp(fluid: { originalName: { regex: "/banner-rlp-na-b3/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
