import React from 'react'

import { Section, AtivosDiv, Ativo } from './style'

import jsonData from './assets/data/data.json'

type AtivosType = {
  spreadAberto: string[];
  spreadAbertoFechado: string[];
}

const AtivosDisponiveisParaOperacao = () => {
  const ativos: AtivosType = jsonData

  return (
    <Section id='hero-rlp' className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fw-600 text-grayscale--500 text-md-center'>Confira os ativos disponíveis para operação</h2>
          </div>
          <div className='col-12'>
            <div className='d-flex justify-content-md-center align-items-md-center'>
              <div className='d-none d-md-block col-md-2 col-lg-3 px-0 mx-0'>
                <span className='d-block fs-20 lh-26 fw-600 text-grayscale--500 text-center'>Grupo spread aberto</span>
              </div>
              <div className='col-6 col-md-4 col-lg-3 px-0 mx-0 mr-md-4'>
                <div>
                  <h3 className='fs-16 lh-20 fs-md-20 lh-md-26 fw-600 text-grayscale--500'>Ticker</h3>
                </div>
                <AtivosDiv className='border-right p-md-4'>
                  <div className='ativos'>
                    {ativos.spreadAberto.map((ativo: string) => (
                      <Ativo key={ativo} className='mr-3 mr-md-0'>
                        <h4 className='fs-16 lh-20 fs-md-20 lh-md-26 fw-600 text-grayscale--500 mb-0'>{ativo}</h4>
                      </Ativo>
                    ))}
                  </div>
                  <div className='d-md-none'>
                    <span className='d-block fs-12 lh-15 fw-600 text-grayscale--500 mb-0 pb-3'>Grupo spead aberto</span>
                  </div>
                </AtivosDiv>
              </div>
              <div className='col-6 col-md-4 col-lg-3 px-0 mx-0'>
                <div>
                  <h3 className='fs-16 lh-20 fs-md-20 lh-md-26 fw-600 text-grayscale--500'>Ticker</h3>
                </div>
                <AtivosDiv className='border-left p-md-4'>
                  <div className='ativos'>
                    {ativos.spreadAbertoFechado.map((ativo: string) => (
                      <Ativo key={ativo} className='ml-3 ml-md-0'>
                        <h4 className='fs-16 lh-20 fs-md-20 lh-md-26 fw-600 text-grayscale--500 mb-0'>{ativo}</h4>
                      </Ativo>
                    ))}
                  </div>
                  <div className='d-md-none'>
                    <span className='d-block fs-12 lh-15 fw-600 text-grayscale--500 mb-0 ml-3'>Grupo spread aberto e fechado</span>
                  </div>
                </AtivosDiv>
              </div>
              <div className='d-none d-md-block col-md-2 col-lg-3 px-0 mx-0'>
                <span className='d-block fs-20 lh-26 fw-600 text-grayscale--500 text-center'>Grupo spread aberto e fechado</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AtivosDisponiveisParaOperacao
