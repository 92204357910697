import React, { ReactNode } from 'react'
import { Section, Card } from './style'

import jsonData from './assets/data/data.json'

import Smartphones from 'inter-frontend-svgs/lib/orangeds/MD/money-up'
import Search from 'inter-frontend-svgs/lib/orangeds/MD/digital-account'
import Shipping from 'inter-frontend-svgs/lib/orangeds/MD/no-taxes'
import DepositByBoleto from 'inter-frontend-svgs/lib/orangeds/MD/pending'

import { orange } from 'src/styles/colors'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

interface IIconsList {
  [index: string]: ReactNode;
}

const ConhecaAsVantagens = () => {
  const data: CardType[] = jsonData

  const icons: IIconsList = {
    moneyUp: <Smartphones color={orange.extra} width={32} height={32} />,
    digitalAccount: <Search color={orange.extra} width={32} height={32} />,
    noTaxes: <Shipping color={orange.extra} width={32} height={32} />,
    pending: <DepositByBoleto color={orange.extra} width={32} height={32} />,
  }

  return (
    <Section id='hero-rlp' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-lg-50 text-md-center fw-600 text-grayscale--500 mb-4 mb-md-5'>Conheça as vantagens do RLP</h2>
          </div>
          {data.map((card: CardType) => (
            <div key={card.icon} className='col-12 col-md-6 mb-4'>
              <Card className='d-flex align-items-md-center h-100'>
                <div className='svg-div'>
                  {icons[card.icon]}
                </div>
                <div>
                  <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-26 fw-600 text-grayscale--500 mb-2' dangerouslySetInnerHTML={{ __html: card.title }} />
                  <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default ConhecaAsVantagens
