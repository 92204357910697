import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`

`

export const BorderText = styled.p`
  border: 2px solid ${grayscale[200]};
  border-radius: 16px;
  padding: 22px;
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 38px 1fr 1fr 1fr 1fr;

  @media ${device.tablet} {
    grid-template-columns: 120px 1fr 1fr 1fr 1fr;
  }
  @media ${device.desktopLG} {
    grid-template-columns: 168px 1fr 1fr 1fr 1fr;
  }
  @media ${device.desktopXL} {
    grid-template-columns: 288px 1fr 1fr 1fr 1fr;
  }

  .border-right {
    border-right: 1px solid ${grayscale[200]};
  }

  .border-bottom {
    border-bottom: 1px solid ${grayscale[200]};
  }
`

export const ListContainer = styled.div`
  div:last-child p {
      border-bottom: none !important;
  }
`
