import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .btn {
    height: 48px !important;
    @media ${device.desktopXL} {
      min-width: 456px;
    }
  }
`

export const Card = styled.div`
  .svg-div {
    margin-right: 16px;
    @media ${device.tablet} {
      margin-right: 9px;
    }
    @media ${device.desktopLG} {
      margin-right: 16px;
    }
  }
`
