import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const RLPNaB3 = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/regras-e-parametros-de-negociacao/oferta-retail-liquidity-provider-rlp/'

  return (
    <Section id='rlp-na-b3' className='py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='d-none d-md-block col-12 col-md-6 col-lg-5'>
            <Img fluid={data.bannerRLPNaB3.fluid} alt='B3 - Brasil, Bolsa, Balcão' />
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-3'>Saiba mais sobre o RLP na B3</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 mb-4'>O RLP é um mecanismo desenvolvido pela Bolsa de Valores brasileira. Para conhecer as regras e parâmetros de negociação, acesse o material preparado pela B3.</p>
            <div>
              <a
                href='https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/regras-e-parametros-de-negociacao/oferta-retail-liquidity-provider-rlp/'
                rel='nofollow'
                className='d-flex btn btn-orange--extra mx-auto text-white text-none'
                target='__blank'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_12',
                    element_action: 'click button',
                    element_name: 'Acessar material',
                    section_name: 'Saiba mais sobre o RLP na B3',
                    redirect_url: link,
                  })
                }}
              >
                Acessar material
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default RLPNaB3
