import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundSM from '../../assets/images/background-hero-rlp-sm.png'
import BackgroundMD from '../../assets/images/background-hero-rlp-md.png'
import BackgroundLG from '../../assets/images/background-hero-rlp-lg.png'
import BackgroundXL from '../../assets/images/background-hero-rlp-xl.png'

import { orange } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${orange.extra};
  background-image: url(${BackgroundSM});
  background-repeat: no-repeat;
  background-size: contain;
  height: 620px;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    background-position: right;
    background-size: auto;
    height: 638px;
  }
  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
  }
  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 766px;
  }

  .bread-crumb {
    span {
      font-weight: 700 !important;
      opacity: 1 !important;
    }
  }

  .btn {
    max-width: 100% !important;
    @media ${device.desktopXL} {
      max-width: 456px !important;
    }
  }
`
