import styled, { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

const BackgroundCSS = css`
  @media ${device.tablet} {
    border-radius: 16px;
    border: 1px solid ${grayscale[300]};
    padding: 24px;
  }
`

export const Section = styled.section`
  p, h4 {
    margin-bottom: 0;
  }

  span {
    font-family: 'Sora';
  }

  .btn {
    height: 48px !important;
    @media ${device.tablet} {
      min-width: 456px !important;
    }
  }
`
export const TitleBox = styled.div`
  padding: 24px;
  background-color: ${white};
  border-bottom: 1px solid ${grayscale[300]};
  ${BackgroundCSS}
`

export const BoxLeft = styled.div`
  padding: 16px;
  background-color: ${white};
  border-right: 1px solid ${grayscale[300]};
  ${BackgroundCSS}
`

export const BoxRight = styled.div`
  padding: 16px;
  background-color: ${white};
  border-left: 1px solid ${grayscale[300]};
  ${BackgroundCSS}
`

export const BoxWithRLP = styled.div`
  padding: 16px;
  background-color: ${white};
  border-right: 1px solid ${grayscale[300]};
  ${BackgroundCSS}
`

export const BoxOutRLP = styled.div`
  padding: 16px;
  background-color: ${white};
  border-left: 1px solid ${grayscale[300]};
  ${BackgroundCSS}
`
