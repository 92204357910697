import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'
import { IValoresDasOperacoes } from './types'
import dataJson from './assets/data/data.json'

const ValoresDasOperacoes = () => {
  const data: IValoresDasOperacoes[] = dataJson
  const [ sendDatalayerEvent ] = useDataLayer()

  const link = 'https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/'

  return (
    <S.Section className='bg-white py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-md-center'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 font-sora mb-lg-5'>Valores das operações com ações</h2>
            <S.BorderText className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-grayscale--500 font-sora'>Operações via Home Broker ou plataformas</S.BorderText>
            <h4 className='fs-20 lh-26 fs-lg-24 lh-lg-30 fw-600 text-grayscale--500 font-sora ml-4 ml-md-0 mb-lg-5'>Custo zero</h4>
            <S.BorderText className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-grayscale--500 font-sora'>Operações via mesa e zeragem compulsória</S.BorderText>
          </div>
          <div className='col-12'>
            <S.ListContainer>
              <S.List className=''>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora border-right border-bottom mb-0 py-3 d-flex align-items-center justify-content-center'>Faixa</p>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora text-right border-bottom mb-0 py-3 d-flex align-items-center justify-content-end'>De</p>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora text-right border-bottom mb-0 py-3 d-flex align-items-center justify-content-end'>Até</p>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora text-right border-bottom mb-0 py-3 d-flex align-items-center justify-content-end'>Custo Variável</p>
                <p className='fs-12 lh-15 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora text-right border-bottom mb-0 py-3 d-flex align-items-center justify-content-end'>Custo Fixo</p>
              </S.List>
              {data.map((value: IValoresDasOperacoes) => (
                <S.List key={value.custoVariavel}>
                  <p className='fs-10 lh-12 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-grayscale--500 fw-700 font-sora border-right mb-0 py-3 border-bottom d-flex align-items-center justify-content-center'>{value.faixa}</p>
                  <p className='fs-10 lh-12 fs-md-14 lh-md-16 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 mb-0 py-3 border-bottom d-flex align-items-center justify-content-end'>{value.de}</p>
                  <p className='fs-10 lh-12 fs-md-14 lh-md-16 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 mb-0 py-3 border-bottom d-flex align-items-center justify-content-end'>{value.ate}</p>
                  <p className='fs-10 lh-12 fs-md-14 lh-md-16 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 mb-0 py-3 border-bottom d-flex align-items-center justify-content-end'>{value.custoVariavel}</p>
                  <p className='fs-10 lh-12 fs-md-14 lh-md-16 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 mb-0 py-3 border-bottom d-flex align-items-center justify-content-end'>{value.custoFixo}</p>
                </S.List>
              ))}
            </S.ListContainer>
          </div>
          <div className='col-12 mt-4'>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--500 text-center'>Taxa mínima de corretagem para operações <span className='d-md-block'>via Mesa e Zeragem Compulsória: <span className='fw-700'>R$ 50,00</span></span></p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 text-grayscale--500 text-center'>
              Consulte a{' '}
              <a
                href='https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/'
                rel='nofollow'
                className='fw-700'
                target='__blank'
                onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_09',
                      element_action: 'click button',
                      element_name: 'Valores das operações com ações',
                      section_name: 'Com a nossa assessoria, você chega mais longe',
                      redirect_url: link,
                    })
                }}
              >Tabela Operacional Básica
              </a> para
              <span className='d-block d-md-inline'>custos dos demais ativos</span> <span className='d-md-block'>operados via mesa</span>
            </p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ValoresDasOperacoes
