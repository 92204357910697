import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
 span {
  font-family: 'Sora';
 }
`

export const AtivosDiv = styled.div`
  background-color: ${white};
  border-color: ${grayscale[300]};

  .ativos {
    div:last-child {
      border-bottom: none;
    }
  }

  @media ${device.tablet} {
    border-radius: 16px;
    border: 1px solid ${grayscale[300]} !important;
  }
`

export const Ativo = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid ${grayscale[200]};
`
